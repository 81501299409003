.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.my-list{
  background-color: rgb(255, 255, 255);
  color: rgb(24, 25, 25);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  opacity: 1;
  position: relative;
  transition-duration: 0.35s;
  transition-timing-function: ease-in-out;
  flex-direction: row;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(225, 232, 237);
  overflow: hidden;
  text-decoration: none;
  -webkit-tap-highlight-color: rgb(245, 248, 250);
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 8px 0px;
  margin-top: 20px;
  padding: 10px!important;
}
.my-card{
  background-color: rgb(255, 255, 255);
  color: rgb(24, 25, 25);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  opacity: 1;
  position: relative;
  transition-duration: 0.35s;
  transition-timing-function: ease-in-out;
  flex-direction: row;
  border-width: 1px;
  border-style: solid;
  border-color:white;
  overflow: hidden;
  text-decoration: none;
  -webkit-tap-highlight-color: rgb(245, 248, 250);
  cursor: pointer;
  /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 8px 0px; */
  margin:20px;
}

.text-over{
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}